<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Add New Contact Us
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCategoryFormalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
        ref="form">
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Title"
              label-for="blog-edit-title"
              class="mb-2">
              <validation-provider #default="{ errors }" name="title"  rules="required">
                <b-form-input
                  id="blog-edit-title"
                  v-model="addCategoryForm.title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Location"
              label-for="blog-edit-slug"
              class="mb-2">
              <validation-provider #default="{ errors }" name="location"  rules="required">
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addCategoryForm.location"
                 />
                  <small class="text-danger">{{ errors[0] }}</small>
              
               
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Phone "
              label-for="blog-edit-category"
              class="mb-2">
              <validation-provider #default="{ errors }" name="phone">
                <b-form-input
                type="text"                  v-model="addCategoryForm.phone"
                 />
                
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Email "
              label-for="blog-edit-category"
              class="mb-2">
              <validation-provider #default="{ errors }" name="email"  rules="required|email">
                <b-form-input
                           v-model="addCategoryForm.email"
                 />
                 <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Working Hours "
              label-for="blog-edit-category"
              class="mb-2">
              <validation-provider #default="{ errors }" name="Working Hours"  rules="required">
                <b-form-input
                type="text"                  v-model="addCategoryForm.working_hours"
                 />
                 <small class="text-danger">{{ errors[0] }}</small>
                
              </validation-provider>
            </b-form-group>
          </b-col>

        
          <b-col cols="12" class="mt-50">
            <b-button
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save">
              Save Changes
            </b-button>

            <b-button v-if="isLoading" variant="primary" disabled class="mr-1">
              Loading...
              <b-spinner small />
              <span class="sr-only"></span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from "@/store";
import Vue from "vue";

export default {
  setup(props, { emit }) {
    // const store = useStore();
    const refInputEl = ref(null);
    const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
    const isLoading = ref(false);
    const refPreviewE2 = ref(null);

    const Tabimage = ref("media/svg/files/blank-image.svg");
    const validLink = ref(true);
    const checkLink = (link) => {
      const formData = new FormData();

      formData.append("slug", link);
      store.dispatch("courses/checkLink", formData).then((response) => {
      
        validLink.value = response.data.valid;
      });
    };

    const addCategoryForm = reactive({
      working_hours: 0,

      email: "",
  
      phone: "",
      location: "",
      title: "",
    });
    const removeSpace = (target) => {
      addCategoryForm.link_id = target
      .toLowerCase() // LowerCase
        .replace(/\s+/g, "-") // space to -
        .replace(/&/g, ``) // & to and
        .replace(/--/g, `-`)
        .replace(/[.,]/g, `-`)
        .replace(/[_]/g, ``)
        .replace(/[!''"":=]/g, '')
        .replace(/[!?%$^~*#()+]/g, ``)
    };
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
    
        refPreviewE2.value.src = base64;
      }
    );
    const addCategoryFormalidate = ref();
    const form = ref();

    const save = () => {
       
      addCategoryFormalidate.value.validate().then(success => {
        if (success) {
      const formData = new FormData();

      formData.append("title", addCategoryForm.title);
      formData.append("location", addCategoryForm.location);
      // formData.append("link_id", addCategoryForm.link_id);
      formData.append("phone", addCategoryForm.phone);
      formData.append("email", addCategoryForm.email);
      formData.append("working_hours", addCategoryForm.working_hours);

    
        isLoading.value = true;
        addCategoryFormalidate.value.validate().then((success) => {
          if (success) {
            store
              .dispatch("contactUs/addContactUs", formData)
              .then((response) => {
                emit("add-category");
                form.value.reset();
                isLoading.value = false;
              
                Vue.swal({
                  title: "Contact Us Added ",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }) .catch((error) => {
                isLoading.value = false;
                Vue.swal({
                  title: "",
                  text: `${error.response.data.message}`,
                  icon: "error",
                  confirmButtonText: "Ok",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              });

          }
        });
     
    }})
    };
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      Tabimage,
      addCategoryForm,
      inputImageRenderer,
      inputImageRendererTab,
      addCategoryFormalidate,
      removeSpace,
      form,
      validLink,
      checkLink,
      isLoading,

      save,
      required,
      email,
    };
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
.mb-2.col-12 {
  margin: -1px !important;
}
.border.rounded.p-2 {
  margin: 8px 1px !important;
}
.col-12 {
  margin: 0px;
}
</style>
